import React from "react"
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from "../images/brand/spectrum-logo-black.png"
import whitebg from '../images/backgrounds/whitebg.jpg'

const navigation = [
  { name: 'Science', href: '#'},
  { name: 'Platform', href: '/platform'},
  { name: 'About', href: '#'},
  { name: 'Blog', href: '/blog'}
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    return (
        <Disclosure as="nav" className="w-screen z-[1000] absolute">
            {({ open }) => (
                <>
                    <div className="w-screen px-2 lg:px-28 bg-repeat" style={{backgroundImage: `url(${whitebg})`}}>
                        <div className="relative flex items-center justify-between h-24">

                            <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center ml-3 p-2 rounded-md text-black hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-700">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>

                            <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <a href="/">
                                        <img
                                            className="block lg:hidden h-16 w-auto"
                                            src={logo}
                                            alt="Spectrum"
                                        />
                                        <img
                                            className="hidden lg:block h-16 w-auto"
                                            src={logo}
                                            alt="Spectrum"
                                        />
                                    </a>
                                </div>
                            </div>

                            <div className="hidden h-auto lg:flex flex-row lg:justify-end sm:ml-6 sm:mr-10">
                                <div className="flex space-x-6">
                                {navigation.map((item) => (
                                    <a
                                    key={item.name}
                                    href={item.href}
                                    className={'text-lg font-semibold text-black hover:text-slate-800 px-3 py-2 rounded-md'}
                                    aria-current={item.current ? 'page' : undefined}
                                    >
                                    {item.name}
                                    </a>
                                ))}
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Mobile Menu */}
                    <Disclosure.Panel className="lg:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1 z-[1000]">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={'text-black dark:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}

                            <a href={'https://conexdepot.store'} target={'_blank'} className="w-fit flex flex-row items-center bg-black text-white dark:text-white hover:bg-gray-700 hover:text-white ml-1 px-3 py-2 rounded-md text-base font-medium">
                                {'DEPOT'}
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                </svg>
                            </a>

                            

                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}